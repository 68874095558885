import React from 'react'
import { graphql } from 'gatsby';
import Layout from '@components/layout'
import CaseStudiesMore from '@components/case-studies-more'
import FacebookShare from '@components/fb-share-button';
import TwitterShare from '@components/tw-share-button';

export default function argos(props) {
  const { siteUrl } = props.data.site.siteMetadata
  const thumbnail = '/images/argos-case1.png'
  const thumbAltText = 'argos case study'
  const description = 'Argos set out to build a next-generation platform for digital curricular experiences. WyeWorks joined the team to help accomplish that mission by augmenting their engineering capacity.'
  const shareUrl = siteUrl + props.location.pathname
  const caseTitle = 'WyeWorks came in to revamp Argos’ educational platform'

  return (
    <Layout
      location={props.location}
      title='Argos'
      namespace='page'
      description={description}
      thumbnail={thumbnail}
    >
      <div className='hero hero-2'>
        <div className='hero-2__background'></div>
        <div className='hero-2__container'>
          <div className='case-hero__wrapper'>
            <div className='case-hero__pattern'>
              <img src='/images/case-hero-pattern.png' alt='' />
            </div>
            <div className='content-block-1__title-label'>
              <h2 className='h2-label'>
                <div className='h2-label__title'>
                  Case Study
                </div>
                <div className='h2-label__seperator'></div>
              </h2>
            </div>
            <div className='case-hero__hero-block'>
              <div className='case-hero__hero-block-wrapper'>
                <div className='case-hero__case-logo cc-logo-white'>
                  <img src='/images/brands/argos-white-brand.png' alt='' />
                </div>
                <h3 className='case-hero__case-title'>
                  {caseTitle}
                </h3>
                <p className='case-hero__description'>
                  {description}
                </p>
              </div>
              <div className='case-hero__hero-block-background'>
                <div className='case-hero__hero-block-gradient'></div>
                <img src='/images/argos-case-card-tall-bg.png' alt='' />
              </div>
            </div>
            <div className='case-hero__case-meta'>
              <ul className='case-meta__list'>
                <li className='case-meta__list-item'>
                  <div className='case-meta__title'>
                    Services Used
                  </div>
                </li>
                <li className='case-meta__list-item'>
                  Backend Development
                </li>
                <li className='case-meta__list-item'>
                  Frontend Development
                </li>
                <li className='case-meta__list-item'>
                  Process Consulting
                </li>
              </ul>
              <ul className='case-meta__list'>
                <li className='case-meta__list-item'>
                  <div className='case-meta__title'>
                    Industry
                  </div>
                </li>
                <li className='case-meta__list-item'>
                  Education
                </li>
              </ul>
              <ul className='case-meta__list'>
                <li className='case-meta__list-item'>
                  <div className='case-meta__title'>
                    Timeline
                  </div>
                </li>
                <li className='case-meta__list-item'>
                  1 year
                </li>
              </ul>
              <div className='social-layout-1'>
                <FacebookShare url={shareUrl} />
                <TwitterShare url={shareUrl} text={caseTitle.replace('WyeWorks', '@WyeWorks')} />
                <div className='social-layout-1__seperator'></div>
                Share This Case
              </div>
            </div>
          </div>
        </div>
      </div>
      <section>
        <div className='long-content__wrapper'>
          <p>
          In early 2021, Argos was born out of an open-source collaboration between groups in two very different universities to re-imagine the textbook for the digital age and return power to educators. The goal is to serve over a hundred thousand across hundreds of universities and schools and have 100 products covering the first two years of the college curriculum.
          </p>
        </div>
      </section>
      <section className='section--full-right'>
        <div className='image-90__wrapper'>
          <img src={thumbnail} alt={thumbAltText} />
        </div>
      </section>
      <section>
        <div className='long-content__wrapper'>
          <h3>
            The solution
          </h3>
          <p>
            We quickly started working alongside the existing team to improve their Elixir & LiveView codebase, taking care of a much needed housekeeping such as cleaning up technical debt, fixing a number of critical bugs and most importantly making it distributed so it could scale horizontally. All that effort set the stage to add key functionalities like Single Sign-on for institutions, a Payments module, and many more. In addition, we worked on a UI redesign to improve the overall user experience. We were also responsible of making the concept of Communities a reality. This allowed institutions that associate with Argos to handle access and rights over the different courses they provide, taking the experience of using the application to another level.
          </p>
          <div className='quote-block'>
            <div className='quote-block__primary-offset'></div>
            <div className='quote-block__quote-wrapper'>
              <div className='quote-block__quote'>
                "WyeWorks has successfully produced quality deliverables, worked hard to improve the client's coding and code review practices. The team is clever, productive, and collaborative in the partnership. Their flexibility has made it possible to change direction based on conditions and shifts in stakeholder priorities."
              </div>
              <div className='quote-block__provider-title'>
                Eric Hilfer
                <br />
                CTO
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='section--full-left'>
        <div className='image-90__wrapper'>
          <img src='/images/argos-case2.png' alt='' />
        </div>
      </section>
      <section>
        <div className='long-content__wrapper'>
          <h3>
            The results
          </h3>
          <p>
            With our help, Argos now enables educators to make changes that have real impact on student learning without having to jump through hoops to work around the technology.
          </p>
        </div>
      </section>

      <CaseStudiesMore cases={['packlane', 'prisma']} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
